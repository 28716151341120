@tailwind base;
@tailwind components;
@tailwind utilities;

/* Public Sans Fonts */
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

html{
    scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Public Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
    .primary-padding {
      @apply px-32 py-4 lg:px-8;
    }
    .overlay-black{
        @apply absolute top-0 w-full h-full bg-primary-dark;
    }
  }


  #landingGrid {
    height: 400px;
    transition: 200ms;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
  }
  
  #landingGrid:has(#house2:hover) {
    grid-template-columns: 2fr 3fr 1fr;
  }
  #landingGrid:has(#house3:hover) {
    grid-template-columns: 1fr 2fr 3fr;
  }

  @media screen and (min-width:1700px ) {
    #landingGrid {
        height: calc(500px + 5vh);
      }
  }